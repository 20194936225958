import Cookies from 'js-cookie'

let currentUserData

export function JSONParseCookies(nameSpaceKey) {
  if(Cookies.get(nameSpaceKey)) {
    currentUserData = currentUserData|| JSON.parse(decodeURIComponent(Cookies.get(nameSpaceKey)));
    currentUserData.do_not_sell = Cookies.get('do_not_sell')

    return currentUserData
  } else {
    return undefined;
  }
}

export default JSONParseCookies('data-current-user');
